
/*
  width: 100%;
  height: calc(100vh - 0px);
  margin: 0 auto;
  overflow: hidden;
  background-color: #e2d1b4; 
  text-align: center; 
} */

:root {
  /* Define the width of the image as a CSS variable */
  --image-width: 896px;
}

.interactive-image-container {
  position: relative;
}

.logo {
  z-index: 3;
  position: absolute;
  right: 0px;
}

.frame-1 {
  z-index: 0;
  position: absolute;
}

.frame-2 {
  z-index: 1;
  position: absolute;
}

.frame-3 {
  z-index: 2;
  position: absolute;
}

/** Desktop / when screen is larger than the background image **/
@media (min-width: 896px) {
  .interactive-image-container {
    /* Center the container */
    width: var(--image-width);
    margin: 0 auto;
  }
  .logo {
    top: 30px;
  }
  .frame-1 {
    left: 0;
  }
  
  .frame-2 {
    left: 125px;
    top: 207px;
  }
  
  .frame-3 {
    left: 285px;
    top: 519px;
  }
}

/** Mobile / when screen is narrower than the background image **/
@media (max-width: 895px) {
  .interactive-image-container {
    width: 100%;
    height: calc(100vh);
    overflow: hidden;
    background-color: lightgray;
  }
  .logo {
    bottom: 30%;
  }
  .frame-1 {
    z-index: 0;
    position: absolute;
    left: calc((100vw - var(--image-width)) / 2);
    bottom: 0;
    max-width: var(--image-width); /* Ensure the image doesn't exceed its intended width */
  }
  .frame-2 {
    left: calc((100vw - var(--image-width)) / 2 + 125px);
    bottom: 210px;
  }
  .frame-3 {
    left: calc((100vw - var(--image-width)) / 2 + 285px);
    bottom: 72px;
  }
}

/* CSS class for the glow effect */
.glow, .inner-glow {
  transition: box-shadow 0.2s ease-in-out;
}

/* Apply the glow when the element is active (e.g., during a tap) */
.glow:hover,
.glow:active,
.glow.active {
  box-shadow: 0 0 15px rgba(255, 255, 0, 0.7); /* Yellow glow */
}
.inner-glow:hover,
.inner-glow:active,
.inner-glow.active {
  box-shadow: inset 0 0 15px rgba(255, 255, 0, 0.7); /* Yellow glow */
}
